import React from 'react';
import { SearchBox } from 'react-instantsearch-dom';

const ProductSearchBox = () => (
  <div className="wrapper pt-12">
    <SearchBox
      autoFocus
      submit={false}
      reset={`Clear`}
      translations={{
        placeholder: 'Search a product',
      }}
    />
  </div>
);

export default ProductSearchBox;
